(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name referees.referee.controller:RefereeCtrl
   *
   * @description
   *
   */
  angular
    .module('neo.home.refereeNewInvoice')
    .controller('RefereeNewInvoiceCtrl', RefereeNewInvoiceCtrl);

  function RefereeNewInvoiceCtrl($mdToast, $scope, $state,  AclService, refereeLicense, $mdDialog, $filter, RefereeLicence,InvoiceRef, refId) {
   var vm = this;
    vm.ctrlName = 'RefereeNewInvoiceCtrl';
     vm.refId=refId;
      
      
      vm.showList = true;
        vm.selected = [];

        vm.select = undefined;
    vm.person=refereeLicense.person;
    vm.title='PARTITS ARBITRATS';
  vm.headers= [
        {id: 'fecha', name: 'Data', canOrder: true},
         {id: 'idreceptor', name: 'Receptor', canOrder: true},
        {id: 'numinvoice', name: 'Num. Factura', canOrder: true},
         {id: 'esport', name: 'Concepte', canOrder: true},
          {id: 'numpartits', name: 'Num. partits', canOrder: true},
        {id: 'preu', name: 'Preu brut', canOrder: true},
        {id: 'total', name: 'Total', canOrder: true}
      ];
      
 vm.values=InvoiceRef;
  vm.buttons = {
        dial: true,
        icon: 'more_vert',
        tooltip: 'ACTIONS',
        actions: []
      };
        vm.buttons.actions.push({
          icon: 'playlist_add',
          tooltip: 'Afegir línia',
          execute: afegirLinia
        });
        
   function afegirLinia() {
        $state.go('home.new-line', {licenseId: refId},{reload:true});
      
      }
      
     
  }
}());


